/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const AllIcons11 = ({ className }) => {
  return (
    <svg
      className={`all-icons-11 ${className}`}
      fill="none"
      height="39"
      viewBox="0 0 28 39"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_85_277)">
        <path
          className="path-2"
          d="M10.8789 20.1332L17.582 20.8364C21.0977 21.1996 22.8789 22.5473 22.8789 24.5395C22.8789 26.4965 21.2266 27.7387 18.5898 27.7387H11.0195V29.6254H18.5898C22.375 29.6254 24.7656 27.6567 24.7656 24.5395C24.7656 21.352 22.4102 19.266 17.8633 18.809L11.1836 18.1059C7.65625 17.7426 5.88672 16.3949 5.88672 14.4027C5.88672 12.4457 7.53906 11.2035 10.1758 11.2035H17.7344V9.31677H10.1758C6.39062 9.31677 4 11.2855 4 14.4027C4 17.5903 6.34375 19.6762 10.8789 20.1332Z"
          fill="black"
          fillOpacity="0.85"
        />
        <path
          className="path-2"
          d="M7.78516 32.4496C9.87109 32.4496 11.5586 30.7504 11.5586 28.6645C11.5586 26.5903 9.87109 24.891 7.78516 24.891C5.69922 24.891 4.01172 26.5903 4.01172 28.6645C4.01172 30.7504 5.69922 32.4496 7.78516 32.4496ZM20.9805 14.0511C23.0781 14.0511 24.7656 12.3519 24.7656 10.266C24.7656 8.19177 23.0781 6.49255 20.9805 6.49255C18.9062 6.49255 17.207 8.19177 17.207 10.266C17.207 12.3519 18.9062 14.0511 20.9805 14.0511ZM20.9805 12.2113C19.9023 12.2113 19.0469 11.3558 19.0469 10.266C19.0469 9.17614 19.9141 8.33239 20.9805 8.33239C22.082 8.33239 22.9258 9.17614 22.9258 10.266C22.9258 11.3558 22.082 12.2113 20.9805 12.2113Z"
          fill="black"
          fillOpacity="0.85"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_85_277">
          <rect className="rect" fill="white" height="25.957" transform="translate(4 6.49255)" width="20.7656" />
        </clipPath>
      </defs>
    </svg>
  );
};
