/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const DivWrapper = ({
  className,
  buttonClassName,
  overlapGroupClassName,
  divClassName,
  text = "Button name",
}) => {
  return (
    <div className={`div-wrapper ${className}`}>
      <button className={`button-2 ${buttonClassName}`}>
        <div className={`overlap-group-14 ${overlapGroupClassName}`}>
          <div className={`button-name-2 ${divClassName}`}>{text}</div>
        </div>
      </button>
    </div>
  );
};

DivWrapper.propTypes = {
  text: PropTypes.string,
};
