/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const AllIcons7 = ({ className }) => {
  return (
    <svg
      className={`all-icons-7 ${className}`}
      fill="none"
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_44_33049)">
        <path
          className="path-2"
          d="M15.2281 25.9062C21.7555 25.9062 27.1813 20.4805 27.1813 13.9531C27.1813 7.41406 21.7555 2 15.2281 2C8.68908 2 3.27502 7.40234 3.27502 13.9414H5.26721C5.26721 8.41016 9.69689 4.00391 15.2281 4.00391C20.7594 4.00391 25.1891 8.42188 25.1891 13.9531C25.1891 19.4844 20.7594 23.9375 15.2281 23.9141C11.8883 23.9023 8.95861 22.2852 7.16564 19.7891C6.80236 19.3086 6.25158 19.1562 5.75939 19.4492C5.29064 19.7422 5.16174 20.3867 5.56017 20.9023C7.78674 23.8906 11.2789 25.9062 15.2281 25.9062ZM1.83361 11.6914C0.989862 11.6914 0.778925 12.2656 1.23596 12.9219L3.86096 16.6484C4.24767 17.1875 4.81017 17.1758 5.18517 16.6484L7.81017 12.9102C8.25549 12.2656 8.04455 11.6914 7.21252 11.6914H1.83361Z"
          fill="black"
          fillOpacity="0.85"
        />
        <path
          className="path-2"
          d="M15.2047 19.7656C17.7125 19.7656 19.5289 18.3711 19.5289 16.4258C19.5289 14.7852 18.6266 13.9062 16.3883 13.3672L14.7125 12.957C13.4117 12.6289 12.8375 12.1719 12.8375 11.3867C12.8375 10.4023 13.7633 9.72266 15.1227 9.72266C16.3531 9.72266 17.0914 10.25 17.5133 11.375C17.7008 11.7852 18.0172 11.9727 18.3453 11.9727C18.9547 11.9727 19.3063 11.4336 19.0953 10.7305C18.7672 9.48828 17.3492 8.17578 15.1227 8.17578C12.7438 8.17578 11.0445 9.54688 11.0445 11.457C11.0445 13.0625 12.0641 14.0469 14.2203 14.5508L15.8961 14.9492C17.1969 15.2539 17.7008 15.6992 17.7008 16.5547C17.7008 17.5625 16.7281 18.2188 15.2047 18.2188C13.8453 18.2188 13.0133 17.7031 12.5914 16.5898C12.4156 16.1914 12.0992 16.0039 11.7711 16.0039C11.1735 16.0039 10.8571 16.4844 10.986 17.1875C11.2672 18.6289 12.9547 19.7656 15.2047 19.7656ZM14.525 20.9141C14.525 21.3008 14.8414 21.6289 15.2281 21.6289C15.6149 21.6289 15.943 21.3008 15.943 20.9141V7.00391C15.943 6.61719 15.6149 6.28906 15.2281 6.28906C14.8414 6.28906 14.525 6.61719 14.525 7.00391V20.9141Z"
          fill="black"
          fillOpacity="0.85"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_44_33049">
          <rect className="rect" fill="white" height="23.918" transform="translate(1 2)" width="26.1813" />
        </clipPath>
      </defs>
    </svg>
  );
};
