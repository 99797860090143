/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconClock = ({ className }) => {
  return (
    <svg
      className={`icon-clock ${className}`}
      fill="none"
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_42_24071)">
        <path className="path-2" d="M25.9062 2H2V25.918H25.9062V2Z" fill="black" opacity="0" />
        <path
          className="path-2"
          d="M13.9531 25.9062C20.4922 25.9062 25.9062 20.4805 25.9062 13.9531C25.9062 7.41406 20.4805 2 13.9414 2C7.41406 2 2 7.41406 2 13.9531C2 20.4805 7.42578 25.9062 13.9531 25.9062ZM13.9531 23.9141C8.42188 23.9141 4.00391 19.4844 4.00391 13.9531C4.00391 8.42188 8.41016 3.99219 13.9414 3.99219C19.4727 3.99219 23.9141 8.42188 23.9141 13.9531C23.9141 19.4844 19.4844 23.9141 13.9531 23.9141Z"
          fill="black"
          fillOpacity="0.85"
        />
        <path
          className="path-2"
          d="M7.82422 15.2188H13.9414C14.3984 15.2188 14.7617 14.8672 14.7617 14.3984V6.5C14.7617 6.04297 14.3984 5.69141 13.9414 5.69141C13.4844 5.69141 13.1328 6.04297 13.1328 6.5V13.5898H7.82422C7.35547 13.5898 7.00391 13.9414 7.00391 14.3984C7.00391 14.8672 7.35547 15.2188 7.82422 15.2188Z"
          fill="black"
          fillOpacity="0.85"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_42_24071">
          <rect className="rect" fill="white" height="23.918" transform="translate(2 2)" width="23.9062" />
        </clipPath>
      </defs>
    </svg>
  );
};
