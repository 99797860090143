
import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const ButtonSolidStyle = ({
  className,
  onclick,
  buttonClassName,
  overlapGroupClassName,
  divClassName,
  text = "Button name",
  href,
  href1,
}) => {
  return (

    <a  className={`button-solid-style ${className}`} href={href} rel="noopener noreferrer" target="_blank">
      <a href={href1} rel="noopener noreferrer" target="_blank">
        <button  className={`button ${buttonClassName}`} >
          <div className={`overlap-group ${overlapGroupClassName}`}>
            <div className={`button-name ${divClassName}`}>{text}</div>
          </div>
        </button>
      </a>
    </a>
  );
};

ButtonSolidStyle.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string,
  href1: PropTypes.string,
};
