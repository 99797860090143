/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconsDropdownSolid3 = ({ className }) => {
  return (
    <svg
      className={`icons-dropdown-solid-3 ${className}`}
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path-2" clipRule="evenodd" d="M4 7L9 12L14 7H4Z" fill="#161C2D" fillRule="evenodd" />
    </svg>
  );
};
