
import React from "react";

import { AllIcons11 } from "../../icons/AllIcons11";
import { AllIcons12 } from "../../icons/AllIcons12";
import { AllIcons2 } from "../../icons/AllIcons2";
import { AllIcons7 } from "../../icons/AllIcons7";
import { AllIcons9 } from "../../icons/AllIcons9";
import { ButtonSolidStyleWrapper } from "../ButtonSolidStyleWrapper";
import { ListStyle } from "../ListStyle";
import "./style.css";
import {CTA} from "../CTA/cta";
import {IconClock} from "../../icons/IconClock";



export const IphonePro =  ({className, iphoneProClassName, headerClassName}) => {

  return (
      <div className={`iphone-pro ${className}`}>
        <div className={`div-2 ${iphoneProClassName}`}>
          <div className="footer-dark-style-wrapper">
            <div className="sections-footer-dark-wrapper">
              <div className="sections-footer-dark">
                <div className="overlap-group-4">
                  <div className="overlap">
                    <div className="privacy-policy-terms-wrapper">
                      <p className="privacy-policy-terms">
                        Индивидуальный предприниматель
                        <br/>
                        Артименок Виктор Петрович
                        <br/>
                        Почтовый адрес: Витебская обл.,Глубокский р-н.,г.п. Подсвилье ,пер.1-й Полесский ,д.8
                        <br/>
                        УНП 391538527
                        <br/>
                        BY61AKBB30130000205180000000 Текущий (расчетный) (BYN)
                        <br/>в ЦБУ208ОАО « АСБ Беларусбанк» в г. Глубокое
                        <br/>
                        БИК AKBBBY2X
                      </p>
                    </div>
                    <div className="element-2">
                      <div className="contact-us">Связаться с нами</div>
                      <div className="get-a-free-consultan">+37533 6574994</div>
                      <div className="get-a-free-consultan-2">auto-master.info@yandex.by</div>
                    </div>
                  </div>
                  <div className="element-3">
                    <p className="element-copyright-al">© 2024</p>
                    <img
                        className="line-copy"
                        alt="Line copy"
                        src="https://auto-master.by/img/line-copy.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overlap-2">
            <div className="CTA-form">
              <div className="overlap-3">
                <div className="rectangle-2"/>
                <img
                    className="rectangle-copy"
                    alt="Rectangle copy"
                    src="https://auto-master.by/img/rectangle-copy.png"
                />
                <div className="content">
                  <p className="with-lots-of-unique">
                    Укажите телефон или почту.
                    <br/>
                    Мы свяжемся с вами в ближайшее время!
                  </p>
                  <div className="get-a-free-consultan-3">Закажите звонок</div>
                  <div className="chat">
                    <div className="overlap-group-5">
                      <div className="oval"/>
                      <img
                          className="chat-round"
                          alt="Chat round"
                          src="https://auto-master.by/img/chat-round.png"
                      />
					  <img/>
                    </div>
                  </div>
                </div>
                <CTA></CTA>

              </div>
            </div>
            <div className="pricing">
              <div className="overlap-5">
                <div className="BG"/>
                <img className="line" alt="Line" src="https://auto-master.by/img/line-10.svg"/>
                <div className="how">
                  <div className="overlap-6">
                    <img className="img" alt="Line copy" src="https://auto-master.by/img/line-10-copy.svg"/>
                    <div className="element-4">
                      <div className="number">
                        <div className="overlap-group-6">
                          <div className="text-wrapper-2">1</div>
                        </div>
                      </div>
                      <p className="p">Заполните форму заявки или закажите звонок и мы свяжемся с Вами</p>
                      <div className="install-app">Заявка</div>
                    </div>
                    <div className="element-copy">
                      <div className="number-2">
                        <div className="overlap-group-7">
                          <div className="text-wrapper-3">2</div>
                        </div>
                      </div>
                      <p className="p">
                        Вы знакомитесь с <a href='/'>договором оферты.</a>
                        <br />
                        Фактом соглашения являеться внесение предоплаты
                      </p>
                      <div className="add-team-members">Договор оферты</div>
                    </div>
                  </div>
                  <div className="element-copy-2">
                    <div className="number">
                      <div className="overlap-group-8">
                        <div className="text-wrapper-4">3</div>
                      </div>
                    </div>
                    <p className="p">Мы оказываем выбранную Вами услугу</p>
                    <div className="start-rolling">Поиск автомобиля</div>
                  </div>
                  <div className="t-itle">
                    <div className="how-does-it-work">Как это работает?</div>
                  </div>
                </div>
                <div className="element-5">
                  <p className="no-credit-card-requi">Возможен осмотр по РБ (0.3 BYN за 1 км)</p>
                  <div className="starter">РАЗОВЫЙ ОСМОТР</div>
                  <div className="month">BYN</div>
                  <div className="element-6">50</div>
                  <ListStyle
                      className="list-style-02"
                      divClassName="list-style-instance"
                      path="https://auto-master.by/img/path-66.svg"
                      text="Осмотр одного автомобиля*"
                  />
                  <ListStyle
                      className="list-style-copy"
                      divClassName="list-style-instance"
                      path="https://auto-master.by/img/path-66.svg"
                      text="Консультации по сделке"
                  />
                  <ListStyle
                      className="list-style-02-instance"
                      divClassName="list-style-instance"
                      path="https://auto-master.by/img/path-66.svg"
                      text="Торг"
                  />
                  <ButtonSolidStyleWrapper
                      buttonNameClassName="button-solid-style-2"
                      className="button-solid-style-03"
                      divClassName="button-solid-style-3"
                      overlapGroupClassName="button-solid-style-03-instance"
                      rectangleClassName="button-solid-style-03-instance"
                      tailRight="https://auto-master.by/img/tail-right.png"
                      tailRightClassName="button-solid-style-4"
                      text="Заказать"

                  />
                </div>
                <div className="element-copy-3">
                  <div className="premium">ПОДБОР АВТОМОБИЛЯ</div>
                  <div className="month-2">BYN</div>
                  <div className="element-7">1000</div>
                  <ListStyle
                      className="list-style-2"
                      divClassName="list-style-instance"
                      path="https://auto-master.by/img/path-66.svg"
                      text="Подбор по Вашим критериям"
                  />
                  <ListStyle
                      className="list-style-copy-2"
                      divClassName="list-style-instance"
                      path="https://auto-master.by/img/path-66.svg"
                      text="Всестороняя проверка авто"
                  />
                  <ListStyle
                      className="list-style-copy-3"
                      divClassName="list-style-3"
                      path="https://auto-master.by/img/path-66.svg"
                      text={
                        <>
                          Предложим несколько
                          <br/>
                          вариантов соответствующим
                          <br/>
                          критериям
                        </>
                      }
                  />
                  <ListStyle
                      className="list-style-copy-4"
                      divClassName="list-style-instance"
                      path="https://auto-master.by/img/path-66.svg"
                      text="Сопровождение сделки"
                  />
                  <ButtonSolidStyleWrapper
                      buttonNameClassName="button-solid-style-2"
                      className="button-solid-style-5"
                      divClassName="button-solid-style-3"
                      overlapGroupClassName="button-solid-style-03-instance"
                      rectangleClassName="button-solid-style-03-instance"
                      tailRight="https://auto-master.by/img/tail-right.png"
                      tailRightClassName="button-solid-style-4"
                      text="Заказать"

                  />
                </div>
                <div className="element-copy-4">
                  <p className="no-credit-card-requi-2">Далее - 0.3 BYN за 1 км.</p>
                  <div className="standard">
                    ОСМОТР
                    <br/>
                    ЭКСПЕРТ НА ДЕНЬ
                  </div>
                  <div className="month-3">BYN</div>
                  <div className="element-8">500</div>
                  <ListStyle
                      className="list-style-4"
                      divClassName="list-style-3"
                      path="https://auto-master.by/img/path-66.svg"
                      text={
                        <>
                          Осмотр неограниченного
                          <br/>
                          кол-ва автомобилей <br/>в течении 8 часов
                        </>
                      }
                  />
                  <ListStyle
                      className="list-style-copy-5"
                      divClassName="list-style-instance"
                      path="https://auto-master.by/img/path-66.svg"
                      text="Консультации по сделке"
                  />
                  <ListStyle
                      className="list-style-copy-6"
                      divClassName="list-style-instance"
                      path="https://auto-master.by/img/path-66.svg"
                      text="Торг"
                  />
                  <ListStyle
                      className="list-style-copy-7"
                      divClassName="list-style-instance"
                      path="https://auto-master.by/img/path-66.svg"
                      text="150 км на авто эксперта*"
                  />

                  <ButtonSolidStyleWrapper
                      buttonNameClassName="button-solid-style-2"
                      className="button-solid-style-6"
                      divClassName="button-solid-style-3"
                      overlapGroupClassName="button-solid-style-03-instance"
                      rectangleClassName="button-solid-style-03-instance"
                      tailRight="https://auto-master.by/img/tail-right.png"
                      tailRightClassName="button-solid-style-4"
                      text="Заказать"

                  />
                </div>
                <div className="title">
                  <div className="pricing-plans">Услуги и цены</div>
                </div>
              </div>
            </div>
            <div className="overlap-wrapper">
              <div className="overlap-7">
                <div className="frame-2">
                  <div className="frame-3">
                    <div className="frame-4">
                      <div className="text-wrapper-5">ЛКП</div>
                      <p className="text-wrapper-6">Оценим внешний вид, состояние ЛКП и антикоррозийных покрытий.</p>
                    </div>
                    <div className="element-wrapper">
                      <div className="element-9">
                        <video className={"animation"} autoPlay loop muted disablePictureInPicture>
                          <source
                              src="https://auto-master.by/animations/0000-0060.webm"
                              type="video/webm"
                          />
                        </video>
                      </div>
                    </div>
                  </div>
                  <div className="frame-3">
                    <div className="frame-4">
                      <div className="text-wrapper-7">Целостность кузова</div>
                      <p className="text-wrapper-6">
                        Исследуем авто на предмет попадания в ДТП, замену несущих элементов кузова.
                      </p>
                    </div>
                    <div className="frame-5">
                      <div className="element-9">
                        <video className={"animation"} autoPlay loop muted disablePictureInPicture>
                          <source
                              src="https://auto-master.by/animations/0000-0090.webm"
                              type="video/webm"
                          />
                        </video>
                      </div>
                    </div>
                  </div>
                  <div className="frame-3">
                    <div className="frame-4">
                      <div className="text-wrapper-8">Электроника</div>
                      <p className="text-wrapper-9">
                        Проверим блоки на наличие ошибок, корректировку прошивок и показаний одометра.
                      </p>
                    </div>
                    <div className="frame-5">
                      <div className="element-9">
                        <video className={"animation"} autoPlay loop muted disablePictureInPicture>
                          <source
                              src="https://auto-master.by/animations/0000-0050.webm"
                              type="video/webm"
                          />
                        </video>
                      </div>
                    </div>
                  </div>
                  <div className="frame-3">
                    <div className="frame-4">
                      <div className="text-wrapper-10">Двигатель и КПП</div>
                      <p className="text-wrapper-6">
                        Проверим двигатель, КПП, систему сцепления, привода и всё навесное оборудование.
                      </p>
                    </div>
                    <div className="element-wrapper">
                      <div className="element-9">
                        <video className={"animation"} autoPlay loop muted disablePictureInPicture>
                          <source
                              src="https://auto-master.by/animations/0000-0028.webm"
                              type="video/webm"
                          />
                        </video>
                      </div>
                    </div>
                  </div>
                  <div className="frame-3">
                    <div className="frame-4">
                      <div className="text-wrapper-11">Ходовая часть</div>
                      <p className="text-wrapper-6">Проверим ходовую часть, поведение авто на дороге.</p>
                    </div>
                    <div className="element-wrapper">
                      <div className="element-9">
                        <video className={"animation"} autoPlay loop muted disablePictureInPicture>
                          <source
                              src="https://auto-master.by/animations/0046-0065.webm"
                              type="video/webm"
                          />
                        </video>
                      </div>
                    </div>
                  </div>
                  <div className="frame-3">
                    <div className="frame-4">
                      <div className="text-wrapper-5">История</div>
                      <p className="text-wrapper-6">
                        Включает в себя проверку истории авто, предмет нахождения в залоге и угоне.
                      </p>
                    </div>
                    <div className="frame-5">
                      <div className="element-9">
                        <img src="https://auto-master.by/img/scale_1200.png"/>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="pricing-plans-wrapper">
                  <p className="pricing-plans-2">
                  <span className="span">
                    Что мы проверяем?
                    <br/>
                  </span>
                    <span
                        className="text-wrapper-12">Гарантируем, что все эти пункты будут соблюдены в полной мере</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="features-wrapper">
            <div className="frame-18"><p className="text-wrapper-27">Мы знаем, что поиск автомобиля может быть
              утомительным процессом, но наша миссия — сделать его максимально простым и приятным. Наш подход к
              подбору автомобилей сочетает в себе передовые технологии, большой личный опыт в сфере автобизнеса,
              правильное юридическое оформление. Все это позволит предложить вам автомобили, которые идеально
              соответствуют вашим желаниям и потребностям.</p></div>
            <div className="features">

              <div className="element-10">
                <div className="text-wrapper-13">Экономим Ваше время</div>
                <p className="with-lots-of-unique-2">
                  Вам не нужно тратить время за постоянным мониторингом интернет площадок и поездками.
                </p>
                <IconClock className="icons-clock"/>
                <div className="ic-layers"/>
              </div>
              <div className="element-10">
                <div className="manage-customers">Экономия денег</div>
                <p className="with-lots-of-unique-2">
                  Мы постараемся свести к нулю потраченные на подбор деньги за счёт торга с продавцом.
                </p>
                <AllIcons7 className="all-icons"/>
              </div>
              <div className="element-10">
                <div className="text-wrapper-13">Уложимся в сроки</div>
                <p className="with-lots-of-unique-2">В 90% случаев подбор авто займёт 1-2&nbsp;&nbsp;недели</p>
                <AllIcons2 className="all-icons-2"/>
              </div>
              <div className="element-10">
                <div className="organize-your-campai">Юридическая чистота</div>
                <div className="with-lots-of-unique-2">Гарантируем юридическую чистоту сделки.</div>
                <AllIcons9 className="all-icons-9"/>
              </div>
              <div className="element-10">
                <div className="text-wrapper-13">Постоянная обратная связь</div>
                <p className="with-lots-of-unique-2">
                  Получайте отчёты об интересующих&nbsp;&nbsp;автомобилях любым удобным для Вас способом.
                </p>
                <AllIcons12 className="all-icons-12"/>
              </div>
              <div className="element-10">
                <div className="organize-your-campai-2">Гибкость</div>
                <p className="with-lots-of-unique-2">
                  Учитываем Ваши пожелания даже если они не были оговорены заранее, подстроимся под Ваше расписание.
                </p>
                <AllIcons11 className="all-icons-11"/>
              </div>
            </div>
          </div>
          <div className="hero">
            <div className="overlap-8">
              <div className="BG-wrapper">
                <div className="BG-2"/>
              </div>
              <div className="hjfd">
                Поиск идеального
                <br/>
                автомобиля начинается здесь
              </div>
              <div className={`header ${headerClassName}`}>
                <div className="shadepro">
                  Авто Мастер
                </div>

              </div>
              <div className={"tel"}>
                <a className={"atel"} href ="tel:+375336574994">+375336574994</a>
              </div>
              <div className="group">
                <img
                    className="element-gthumb"
                    alt="Element gthumb"
                    src="https://auto-master.by/img/11302-gthumb.png"
                />
                <img
                    className="element-peugeot-sw"
                    alt="Element peugeot sw"
                    src="https://auto-master.by/img/15-peugeot-508.png"
                />
                <img
                    className="element-eefe"
                    alt="Element"
                    src="https://auto-master.by/img/1-02ee71fe.png"
                />
                <div className="rectangle-3"/>
                <div className="rectangle-4"/>
                <div className="ellipse"/>
                <img className="images" alt="Images" src="https://auto-master.by/img/images-1.png"/>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
