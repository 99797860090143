import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { Frame } from "../../components/Frame";
import { IphonePro } from "../../components/IphonePro";
import { MacbookAir } from "../../components/MacbookAir";
import "./style.css";

export const FrameScreen = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="frame-screen">
      <div
        className="frame-19"
        style={{
          height:
            screenWidth < 999
              ? "10315px"
              : screenWidth >= 999 && screenWidth < 1600
              ? "5353px"
              : screenWidth >= 1600
              ? "5507px"
              : undefined,
          width:
            screenWidth < 999
              ? "393px"
              : screenWidth >= 999 && screenWidth < 1600
              ? "1280px"
              : screenWidth >= 1601
              ? "1610px"
              : undefined,
        }}
      >
        {screenWidth < 999 && (
          <IphonePro
            className="instance-node"
            headerClassName="instance-node-2"
            iphoneProClassName="iphone-14-15-pro-1"
          />
        )}

        {screenWidth >= 999 && screenWidth < 1600 && (
          <MacbookAir
            className="instance-node"
            headerClassName="instance-node-2"
            iphoneProClassName="instance-node"
            iphoneProClassNameOverride="macbook-air-1"
            macbookAirClassName="macbook-air-1"
            titleClassName="macbook-air-instance"
          />
        )}

        {screenWidth >= 1600 && (
          <Frame
            className="instance-node"
            elementWebApplicationClassName="frame-instance"
            headerClassName="instance-node-2"
          />
        )}
      </div>
    </div>
  );
};
