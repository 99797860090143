/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const AllIcons15 = ({ className }) => {
  return (
    <svg
      className={`all-icons-15 ${className}`}
      fill="none"
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_47_33124)">
        <path
          className="path-2"
          d="M10.8789 14.6406L17.582 15.3438C21.0977 15.707 22.8789 17.0547 22.8789 19.0469C22.8789 21.0039 21.2266 22.2461 18.5898 22.2461H11.0195V24.1328H18.5898C22.375 24.1328 24.7656 22.1641 24.7656 19.0469C24.7656 15.8594 22.4102 13.7734 17.8633 13.3164L11.1836 12.6133C7.65625 12.25 5.88672 10.9023 5.88672 8.91016C5.88672 6.95312 7.53906 5.71094 10.1758 5.71094H17.7344V3.82422H10.1758C6.39062 3.82422 4 5.79297 4 8.91016C4 12.0977 6.34375 14.1836 10.8789 14.6406Z"
          fill="black"
          fillOpacity="0.85"
        />
        <path
          className="path-2"
          d="M7.78516 26.957C9.87109 26.957 11.5586 25.2578 11.5586 23.1719C11.5586 21.0977 9.87109 19.3984 7.78516 19.3984C5.69922 19.3984 4.01172 21.0977 4.01172 23.1719C4.01172 25.2578 5.69922 26.957 7.78516 26.957ZM20.9805 8.55859C23.0781 8.55859 24.7656 6.85938 24.7656 4.77344C24.7656 2.69922 23.0781 1 20.9805 1C18.9062 1 17.207 2.69922 17.207 4.77344C17.207 6.85938 18.9062 8.55859 20.9805 8.55859ZM20.9805 6.71875C19.9023 6.71875 19.0469 5.86328 19.0469 4.77344C19.0469 3.68359 19.9141 2.83984 20.9805 2.83984C22.082 2.83984 22.9258 3.68359 22.9258 4.77344C22.9258 5.86328 22.082 6.71875 20.9805 6.71875Z"
          fill="black"
          fillOpacity="0.85"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_47_33124">
          <rect className="rect" fill="white" height="25.957" transform="translate(4 1)" width="20.7656" />
        </clipPath>
      </defs>
    </svg>
  );
};
