/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { AllIcons11 } from "../../icons/AllIcons11";
import { AllIcons12 } from "../../icons/AllIcons12";
import { AllIcons2 } from "../../icons/AllIcons2";
import { AllIcons7 } from "../../icons/AllIcons7";
import { AllIcons9 } from "../../icons/AllIcons9";
import { ButtonSolidStyle } from "../ButtonSolidStyle";
import { ButtonSolidStyleWrapper } from "../ButtonSolidStyleWrapper";
import { InputDropdownLabel } from "../InputDropdownLabel";
import { InputRegular } from "../InputRegular";
import { ListStyle } from "../ListStyle";
import "./style.css";
import {CTA} from "../CTA/cta";
import {IconClock} from "../../icons/IconClock";

export const MacbookAir = ({
  className,
  macbookAirClassName,
  iphoneProClassName,
  iphoneProClassNameOverride,
  titleClassName,
  headerClassName,
}) => {
  return (
    <div className={`macbook-air ${className}`}>
      <div className={`iphone-pro-wrapper ${macbookAirClassName}`}>
        <div className={`macbook-air ${iphoneProClassName}`}>
          <div className={`iphone-pro-2 ${iphoneProClassNameOverride}`}>
            <div className="footer-dark-style-2">
              <div className="sections-footer-dark-2">
                <div className="element-14">
                  <p className="privacy-policy-terms-2">
                    Индивидуальный предприниматель
                    <br />
                    Артименок Виктор Петрович
                    <br />
                    Почтовый адрес: Витебская обл.,Глубокский р-н.,г.п. Подсвилье ,пер.1-й Полесский ,д.8
                    <br />
                    УНП 391538527
                    <br />
                    BY61AKBB30130000205180000000 Текущий (расчетный)  (BYN)
                    <br />в ЦБУ208ОАО « АСБ Беларусбанк» в г. Глубокое
                    <br />
                    БИК AKBBBY2X
                  </p>
                </div>
                <div className="element-15">
                  <div className="contact-us-2">Связаться с нами</div>
                  <div className="get-a-free-consultan-4">+37533 6574994</div>
                  <div className="get-a-free-consultan-5">auto-master.info@yandex.by</div>
                </div>
                <div className="element-16">
                  <p className="element-copyright-al-2">© 2024</p>
                  <img
                    className="line-copy-2"
                    alt="Line copy"
                    src="https://auto-master.by/img/line-copy.svg"
                  />
                </div>
              </div>
            </div>
            <div className="overlap-9">
              <div className="pricing-2">
                <div className={`title-2 ${titleClassName}`}>
                  <div className="pricing-plans-3">Услуги и цены</div>
                </div>
                <div className="frame-6">
                  <div className="how-2">
                    <div className="overlap-10">
                      <div className="frame-7">
                        <div className="div-3">
                          <div className="number-3">
                            <div className="overlap-group-9">
                              <div className="text-wrapper-14">1</div>
                            </div>
                          </div>
                          <p className="with-lots-of-unique-7">
                            Заполните форму заявки или закажите звонок и мы свяжемся с Вами
                          </p>
                          <div className="install-app-2">Заявка</div>
                        </div>
                        <div className="div-3">
                          <div className="number-4">
                            <div className="overlap-group-10">
                              <div className="text-wrapper-15">2</div>
                            </div>
                          </div>
                          <p className="with-lots-of-unique-7">
                            Вы знакомитесь с <a href='https://auto-master.by/public.pdf'>договором оферты.</a>
                            <br />
                            Фактом соглашения являеться внесение предоплаты
                          </p>
                          <div className="add-team-members-2">Договор оферты</div>
                        </div>
                        <div className="div-3">
                          <div className="number-3">
                            <div className="overlap-group-11">
                              <div className="text-wrapper-16">3</div>
                            </div>
                          </div>
                          <p className="with-lots-of-unique-7">Мы оказываем выбранную Вами услугу</p>
                          <div className="start-rolling-2">Поиск автомобиля</div>
                        </div>
                      </div>
                      <div className="how-does-it-work-wrapper">
                        <div className="how-does-it-work-2">Как это работает?</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-8">
                    <div className="element-17">
                      <p className="no-credit-card-requi-3">Возможен осмотр по РБ (0.3 BYN за 1 км)</p>
                      <div className="starter-2">РАЗОВЫЙ ОСМОТР</div>
                      <div className="month-4">BYN</div>
                      <div className="element-18">50</div>
                      <ListStyle
                        className="list-style-5"
                        divClassName="list-style-6"
                        path="https://auto-master.by/img/path-66.svg"
                        text="Осмотр одного автомобиля*"
                      />
                      <ListStyle
                        className="list-style-copy-8"
                        divClassName="list-style-6"
                        path="https://auto-master.by/img/path-66.svg"
                        text="Консультации по сделке"
                      />
                      <ListStyle
                        className="list-style-copy-9"
                        divClassName="list-style-6"
                        path="https://auto-master.by/img/path-66.svg"
                        text="Торг"
                      />
                      <ButtonSolidStyleWrapper
                        buttonNameClassName="button-solid-style-9"
                        className="button-solid-style-7"
                        divClassName="button-solid-style-10"
                        overlapGroupClassName="button-solid-style-8"
                        rectangleClassName="button-solid-style-8"
                        tailRight="https://auto-master.by/img/tail-right.png"
                        tailRightClassName="button-solid-style-11"
                        text="Заказать"
                      />
                    </div>
                    <div className="element-copy-5">
                      <p className="no-credit-card-requi-4">Далее - 0.3 BYN за 1 км.</p>
                      <div className="standard-2">
                        ОСМОТР
                        <br />
                        ЭКСПЕРТ НА ДЕНЬ
                      </div>
                      <div className="month-5">BYN</div>
                      <div className="element-19">500</div>
                      <ListStyle
                        className="list-style-7"
                        divClassName="list-style-8"
                        path="https://auto-master.by/img/path-66.svg"
                        text={
                          <>
                            Осмотр неограниченного
                            <br />
                            кол-ва автомобилей <br />в течении 8 часов
                          </>
                        }
                      />
                      <ListStyle
                        className="list-style-copy-10"
                        divClassName="list-style-6"
                        path="https://auto-master.by/img/path-66.svg"
                        text="Консультации по сделке"
                      />
                      <ListStyle
                        className="list-style-copy-11"
                        divClassName="list-style-6"
                        path="https://auto-master.by/img/path-66.svg"
                        text="Торг"
                      />
                      <ListStyle
                        className="list-style-copy-12"
                        divClassName="list-style-6"
                        path="https://auto-master.by/img/path-66.svg"
                        text="150 км на авто эксперта*"
                      />
                      <ButtonSolidStyleWrapper
                        buttonNameClassName="button-solid-style-9"
                        className="button-solid-style-12"
                        divClassName="button-solid-style-10"
                        overlapGroupClassName="button-solid-style-8"
                        rectangleClassName="button-solid-style-8"
                        tailRight="https://auto-master.by/img/tail-right.png"
                        tailRightClassName="button-solid-style-11"
                        text="Заказать"
                      />
                    </div>
                    <div className="element-copy-5">
                      <div className="premium-2">ПОДБОР АВТОМОБИЛЯ</div>
                      <div className="month-6">BYN</div>
                      <div className="element-20">1000</div>
                      <ListStyle
                        className="list-style-9"
                        divClassName="list-style-6"
                        path="https://auto-master.by/img/path-66.svg"
                        text="Подбор по Вашим критериям"
                      />
                      <ListStyle
                        className="list-style-copy-13"
                        divClassName="list-style-6"
                        path="https://auto-master.by/img/path-66.svg"
                        text="Всестороняя проверка авто"
                      />
                      <ListStyle
                        className="list-style-copy-14"
                        divClassName="list-style-8"
                        path="https://auto-master.by/img/path-66.svg"
                        text={
                          <>
                            Предложим несколько
                            <br />
                            вариантов соответствующим
                            <br />
                            критериям
                          </>
                        }
                      />
                      <ListStyle
                        className="list-style-copy-15"
                        divClassName="list-style-6"
                        path="https://auto-master.by/img/path-66.svg"
                        text="Сопровождение сделки"
                      />
                      <ButtonSolidStyleWrapper
                        buttonNameClassName="button-solid-style-9"
                        className="button-solid-style-13"
                        divClassName="button-solid-style-10"
                        overlapGroupClassName="button-solid-style-8"
                        rectangleClassName="button-solid-style-8"
                        tailRight="https://auto-master.by/img/tail-right.png"
                        tailRightClassName="button-solid-style-11"
                        text="Заказать"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-9">
                <div className="overlap-11">
                  <div className="rectangle-5" />
                  <img
                    className="rectangle-copy-2"
                    alt="Rectangle copy"
                    src="https://auto-master.by/img/rectangle-copy.png"
                  />
                  <div className="content-2">
                    <p className="with-lots-of-unique-8">
                      Укажите телефон или почту.
                      <br />
                      Мы свяжемся с вами в ближайшее время!
                    </p>
                    <div className="get-a-free-consultan-6">Закажите звонок</div>
                    <div className="chat-2">
                      <div className="overlap-group-12">
                        <div className="oval-2" />
                        <img
                          className="chat-round-2"
                          alt="Chat round"
                          src="https://auto-master.by/img/chat-round.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="CTA-2">
                    <div className="overlap-12">
                      <CTA></CTA>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-10">
              <div className="overlap-13">
                <div className="frame-11">
                  <div className="frame-12">
                    <div className="frame-13">
                      <div className="text-wrapper-17">ЛКП</div>
                      <p className="text-wrapper-18">Оценим внешний вид, состояние ЛКП и антикоррозийных покрытий.</p>
                    </div>
                    <div className="frame-14">
                      <div className="element-21">
                        <video className={"animation"} autoPlay loop muted disablePictureInPicture>
                          <source
                              src="https://auto-master.by/animations/0000-0060.webm"
                              type="video/webm"
                          />
                        </video>
                      </div>
                    </div>
                  </div>
                  <div className="frame-12">
                    <div className="frame-13">
                      <div className="text-wrapper-19">Целостность кузова</div>
                      <p className="text-wrapper-18">
                        Исследуем авто на предмет попадания в ДТП, замену несущих элементов кузова.
                      </p>
                    </div>
                    <div className="frame-15">
                      <div className="element-21">
                        <video className={"animation"} autoPlay loop muted disablePictureInPicture>
                          <source
                              src="https://auto-master.by/animations/0000-0090.webm"
                              type="video/webm"
                          />
                        </video>
                      </div>
                    </div>
                  </div>
                  <div className="frame-12">
                    <div className="frame-13">
                      <div className="text-wrapper-20">Электроника</div>
                      <p className="text-wrapper-21">
                        Проверим блоки на наличие ошибок, корректировку прошивок и показаний одометра.
                      </p>
                    </div>
                    <div className="frame-15">
                      <div className="element-21">
                        <video className={"animation"} autoPlay loop muted disablePictureInPicture>
                          <source
                              src="https://auto-master.by/animations/0000-0050.webm"
                              type="video/webm"
                          />
                        </video>
                      </div>
                    </div>
                  </div>
                  <div className="frame-12">
                    <div className="frame-13">
                      <div className="text-wrapper-22">Двигатель и КПП</div>
                      <p className="text-wrapper-18">
                        Проверим двигатель, КПП, систему сцепления, привода и всё навесное оборудование.
                      </p>
                    </div>
                    <div className="frame-14">
                      <div className="element-21">
                        <video className={"animation"} autoPlay loop muted disablePictureInPicture>
                          <source
                              src="https://auto-master.by/animations/0000-0028.webm"
                              type="video/webm"
                          />
                        </video>
                      </div>
                    </div>
                  </div>
                  <div className="frame-12">
                    <div className="frame-13">
                      <div className="text-wrapper-23">Ходовая часть</div>
                      <p className="text-wrapper-18">Проверим ходовую часть, поведение авто на дороге.</p>
                    </div>
                    <div className="frame-14">
                      <div className="element-21">
                        <video className={"animation"} autoPlay loop muted disablePictureInPicture>
                          <source
                              src="https://auto-master.by/animations/0046-0065.webm"
                              type="video/webm"
                          />
                        </video>
                      </div>
                    </div>
                  </div>
                  <div className="frame-12">
                    <div className="frame-13">
                      <div className="text-wrapper-17">История</div>
                      <p className="text-wrapper-18">
                        Включает в себя проверку истории авто, предмет нахождения в залоге и угоне.
                      </p>
                    </div>
                    <div className="frame-15">
                      <div className="element-21">
                        <img src="https://auto-master.by/img/scale_1200.png"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-16">
                  <p className="pricing-plans-4">
                    <span className="text-wrapper-24">
                      Что мы проверяем?
                      <br />
                    </span>
                    <span className="text-wrapper-25">
                      Гарантируем, что все эти пункты будут соблюдены в полной мере
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="frame-17">
              <div className="features-2">
                <div className="element-22">
                  <div className="text-wrapper-26">Экономим Ваше время</div>
                  <p className="with-lots-of-unique-9">
                    Вам не нужно тратить время за постоянным мониторингом интернет площадок и поездками.
                  </p>
                  <IconClock className="icons-clock"/>
                  <div className="ic-layers-px" />
                </div>
                <div className="element-23">
                  <div className="manage-customers-2">Экономия денег</div>
                  <p className="with-lots-of-unique-10">
                    Мы постараемся свести к нулю потраченные на подбор деньги за счёт торга с продавцом.
                  </p>
                  <AllIcons7 className="all-icons-7" />
                </div>
                <div className="element-22">
                  <div className="text-wrapper-26">Уложимся в сроки</div>
                  <p className="with-lots-of-unique-9">В 90% случаев подбор авто займёт 1-2&nbsp;&nbsp;недели</p>
                  <AllIcons2 className="all-icons-16" />
                </div>
                <div className="element-22">
                  <div className="organize-your-campai-3">Юридическая чистота</div>
                  <div className="with-lots-of-unique-11">Гарантируем юридическую чистоту сделки.</div>
                  <AllIcons9 className="all-icons-3" />
                </div>
                <div className="element-24">
                  <div className="text-wrapper-26">Постоянная обратная связь</div>
                  <p className="with-lots-of-unique-12">
                    Получайте отчёты об интересующих&nbsp;&nbsp;автомобилях любым удобным для Вас способом.
                  </p>
                  <AllIcons12 className="all-icons-10" />
                </div>
                <div className="element-25">
                  <div className="organize-your-campai-4">Гибкость</div>
                  <p className="with-lots-of-unique-13">
                    Учитываем Ваши пожелания даже если они не были оговорены заранее, подстроимся под Ваше расписание.
                  </p>
                  <AllIcons11 className="all-icons-5" />
                </div>
              </div>
              <div className="frame-18">
                <p className="text-wrapper-27">
                  Мы знаем, что поиск автомобиля может быть утомительным процессом, но наша миссия — сделать его максимально
                  простым и приятным. Наш подход к подбору автомобилей сочетает в себе передовые технологии,
                  большой личный опыт в сфере автобизнеса, правильное юридическое оформление. Все это позволит предложить вам автомобили,
                  которые идеально соответствуют вашим желаниям и потребностям.
                </p>
              </div>
            </div>
            <div className="hero-2">
              <div className="overlap-14">
                <div className="BG-3">
                  <div className="BG-4" />
                </div>
                <div className="hjfd-2">
                  Поиск идеального
                  <br />
                  автомобиля начинается здесь
                </div>
                <div className={`shadepro-wrapper ${headerClassName}`}>
                  <div className="shadepro-2">Авто Мастер</div>
                  <div className="shadepro-4"><tel>+37533 6574994</tel></div>
                </div>
                <div className="group-2">
                  <img
                    className="element-gthumb-2"
                    alt="Element gthumb"
                    src="https://auto-master.by/img/11302-gthumb.png"
                  />
                  <img
                    className="element-peugeot-sw-2"
                    alt="Element peugeot sw"
                    src="https://auto-master.by/img/15-peugeot-508.png"
                  />
                  <img
                    className="element-26"
                    alt="Element"
                    src="https://auto-master.by/img/1-02ee71fe.png"
                  />
                  <div className="rectangle-6" />
                  <div className="rectangle-7" />
                  <div className="ellipse-2" />
                  <img className="images-2" alt="Images" src="https://auto-master.by/img/images-1.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
