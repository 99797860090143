/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { AllIcons12 } from "../../icons/AllIcons12";
import { AllIcons15 } from "../../icons/AllIcons15";
import { AllIcons2 } from "../../icons/AllIcons2";
import { AllIcons7 } from "../../icons/AllIcons7";
import { AllIcons9 } from "../../icons/AllIcons9";
import { ButtonSolidStyle } from "../ButtonSolidStyle";
import { ButtonSolidStyleWrapper } from "../ButtonSolidStyleWrapper";
import { DivWrapper } from "../DivWrapper";
import { FooterDarkStyle } from "../FooterDarkStyle";
import { InputDropdownLabel } from "../InputDropdownLabel";
import { InputRegular } from "../InputRegular";
import { ListStyle } from "../ListStyle";
import "./style.css";
import {CTA} from "../CTA/cta";
import {IconClock} from "../../icons/IconClock";

export const Frame = ({ className, elementWebApplicationClassName, headerClassName }) => {
  return (
    <div className={`frame ${className}`}>
      <div className={`element-web-application ${elementWebApplicationClassName}`}>
        <FooterDarkStyle
          className="footer-dark-style-02"
          lineCopy="https://auto-master.by/img/line-copy.svg"
        />
        <div className="overlap-15">
          <div className="pricing-3">
            <div className="overlap-16">
              <div className="overlap-17">
                <div className="title-3">
                  <div className="pricing-plans-5">Услуги и цены</div>
                </div>
                <div className="element-30">
                  <p className="no-credit-card-requi-5">Возможен осмотр по РБ (0.3 BYN за 1 км)</p>
                  <div className="starter-3">РАЗОВЫЙ ОСМОТР</div>
                  <div className="month-7">BYN</div>
                  <div className="element-31">50</div>
                  <ListStyle
                    className="list-style-10"
                    divClassName="list-style-11"
                    path="https://auto-master.by/img/path-66.svg"
                    text="Осмотр одного автомобиля*"
                  />
                  <ListStyle
                    className="list-style-copy-16"
                    divClassName="list-style-11"
                    path="https://auto-master.by/img/path-66.svg"
                    text="Консультации по сделке"
                  />
                  <ListStyle
                    className="list-style-copy-17"
                    divClassName="list-style-11"
                    path="https://auto-master.by/img/path-66.svg"
                    text="Торг"
                  />
                  <ButtonSolidStyleWrapper
                    buttonNameClassName="button-solid-style-20"
                    className="button-solid-style-18"
                    divClassName="button-solid-style-21"
                    overlapGroupClassName="button-solid-style-19"
                    rectangleClassName="button-solid-style-19"
                    tailRight="https://auto-master.by/img/tail-right.png"
                    tailRightClassName="button-solid-style-22"
                    text="Заказать"
                  />
                </div>
                <div className="element-copy-6">
                  <p className="no-credit-card-requi-6">Далее - 0.3 BYN за 1 км.</p>
                  <div className="standard-3">
                    ОСМОТР
                    <br />
                    ЭКСПЕРТ НА ДЕНЬ
                  </div>
                  <div className="month-8">BYN</div>
                  <div className="element-32">500</div>
                  <ListStyle
                    className="list-style-12"
                    divClassName="list-style-13"
                    path="https://auto-master.by/img/path-66.svg"
                    text={"Осмотр неограниченного\n кол-ва автомобилей \n в течении 8 часов "}
                  />
                  <ListStyle
                    className="list-style-copy-18"
                    divClassName="list-style-11"
                    path="https://auto-master.by/img/path-66.svg"
                    text="Консультации по сделке"
                  />
                  <ListStyle
                    className="list-style-copy-19"
                    divClassName="list-style-11"
                    path="https://auto-master.by/img/path-66.svg"
                    text="Торг"
                  />
                  <ListStyle
                    className="list-style-copy-20"
                    divClassName="list-style-11"
                    path="https://auto-master.by/img/path-66.svg"
                    text="150 км на авто эксперта*"
                  />
                  <ButtonSolidStyleWrapper
                    buttonNameClassName="button-solid-style-20"
                    className="button-solid-style-23"
                    divClassName="button-solid-style-21"
                    overlapGroupClassName="button-solid-style-19"
                    rectangleClassName="button-solid-style-19"
                    tailRight="https://auto-master.by/img/tail-right.png"
                    tailRightClassName="button-solid-style-22"
                    text="Заказать"
                  />
                </div>
                <div className="element-copy-7">
                  <div className="premium-3">ПОДБОР АВТОМОБИЛЯ</div>
                  <div className="month-9">BYN</div>
                  <div className="element-33">1000</div>
                  <ListStyle
                    className="list-style-14"
                    divClassName="list-style-11"
                    path="https://auto-master.by/img/path-66.svg"
                    text="Подбор по Вашим критериям"
                  />
                  <ListStyle
                    className="list-style-copy-21"
                    divClassName="list-style-11"
                    path="https://auto-master.by/img/path-66.svg"
                    text="Всестороняя проверка авто"
                  />
                  <ListStyle
                    className="list-style-copy-22"
                    divClassName="list-style-13"
                    path="https://auto-master.by/img/path-66.svg"
                    text={
                      <>
                        Предложим несколько
                        <br />
                        вариантов соответствующим
                        <br />
                        критериям
                      </>
                    }
                  />
                  <ListStyle
                    className="list-style-copy-23"
                    divClassName="list-style-11"
                    path="https://auto-master.by/img/path-66.svg"
                    text="Сопровождение сделки"
                  />
                  <ButtonSolidStyleWrapper
                    buttonNameClassName="button-solid-style-20"
                    className="button-solid-style-24"
                    divClassName="button-solid-style-21"
                    overlapGroupClassName="button-solid-style-19"
                    rectangleClassName="button-solid-style-19"
                    tailRight="https://auto-master.by/img/tail-right.png"
                    tailRightClassName="button-solid-style-22"
                    text="Заказать"
                  />
                </div>
              </div>
              <div className="how-3">
                <div className="t-itle-2">
                  <div className="how-does-it-work-3">Как это работает?</div>
                </div>
                <div className="overlap-18">
                  <img className="line-2" alt="Line" src="https://auto-master.by/img/line-10.svg" />
                  <img
                    className="line-copy-4"
                    alt="Line copy"
                    src="https://auto-master.by/img/line-10-copy.svg"
                  />
                  <div className="element-34">
                    <div className="number-5">
                      <div className="overlap-group-15">
                        <div className="text-wrapper-28">1</div>
                      </div>
                    </div>
                    <p className="with-lots-of-unique-14">
                      Заполните форму заявки или закажите звонок и мы свяжемся с Вами
                    </p>
                    <div className="install-app-3">Заявка</div>
                  </div>
                  <div className="element-copy-8">
                    <div className="number-5">
                      <div className="overlap-group-16">
                        <div className="text-wrapper-29">2</div>
                      </div>
                    </div>
                    <p className="with-lots-of-unique-14">
                      Вы знакомитесь с <a href='https://auto-master.by/public.pdf'>договором оферты.</a>
                      <br />
                      Фактом соглашения являеться внесение предоплаты
                    </p>
                    <div className="add-team-members-3">Договор оферты</div>
                  </div>
                  <div className="element-copy-9">
                    <div className="number-5">
                      <div className="overlap-group-17">
                        <div className="text-wrapper-30">3</div>
                      </div>
                    </div>
                    <p className="with-lots-of-unique-14">Мы оказываем выбранную Вами услугу</p>
                    <div className="start-rolling-3">Поиск автомобиля</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="CTA-form-2">
            <div className="overlap-19">
              <div className="rectangle-8" />
              <img
                className="rectangle-copy-3"
                alt="Rectangle copy"
                src="https://auto-master.by/img/rectangle-copy.png"
              />
              <div className="CTA-3">
                <div className="overlap-20">
                  <CTA></CTA>
                </div>
              </div>
              <div className="content-3">
                <p className="with-lots-of-unique-15">
                  Укажите телефон или почту.
                  <br />
                  Мы свяжемся с вами в ближайшее время!
                </p>
                <div className="get-a-free-consultan-9">Закажите звонок</div>
                <div className="chat-3">
                  <div className="overlap-group-18">
                    <div className="oval-3" />
                    <img
                      className="chat-round-3"
                      alt="Chat round"
                      src="https://auto-master.by/img/chat-round.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="div-4">
          <div className="element-wrapper-2">
            <div className="element-35">
              <video className={"animation"} autoPlay loop muted disablePictureInPicture>
                <source
                    src="https://auto-master.by/animations/0046-0065.webm"
                    type="video/webm"
                />
              </video>
            </div>
          </div>
          <div className="element-wrapper-3">
            <div className="element-35">
              <video className={"animation"} autoPlay loop muted disablePictureInPicture>
                <source
                    src="https://auto-master.by/animations/0000-0050.webm"
                    type="video/webm"
                />
              </video>
            </div>
          </div>
          <div className="element-wrapper-4">
            <div className="element-35">
              <video className={"animation"} autoPlay loop muted disablePictureInPicture>
                <source
                    src="https://auto-master.by/animations/0000-0060.webm"
                    type="video/webm"
                />
              </video>
            </div>
          </div>
          <div className="div-5">
            <div className="text-wrapper-31">Ходовая часть</div>
            <p className="text-wrapper-32">Проверим ходовую часть, поведение авто на дороге.</p>
          </div>
          <div className="div-6">
            <div className="text-wrapper-33">ЛКП</div>
            <p className="text-wrapper-32">Оценим внешний вид, состояние ЛКП и антикоррозийных покрытий.</p>
          </div>
          <div className="div-7">
            <div className="text-wrapper-34">Электроника</div>
            <p className="text-wrapper-35">
              Проверим блоки на наличие ошибок, корректировку прошивок и показаний одометра.
            </p>
          </div>
          <div className="div-8">
            <div className="text-wrapper-33">История</div>
            <p className="text-wrapper-32">
              Включает в себя проверку истории авто, предмет нахождения в залоге и угоне.
            </p>
          </div>
          <div className="div-9">
            <div className="text-wrapper-36">Двигатель и КПП</div>
            <p className="text-wrapper-32">
              Проверим двигатель, КПП, систему сцепления, привода и всё навесное оборудование.
            </p>
          </div>
          <div className="element-wrapper-5">
            <div className="element-35">
              <img src="https://auto-master.by/img/scale_1200.png"/>
            </div>
          </div>
          <div className="element-wrapper-6">
            <div className="element-35">
              <video className={"animation"} autoPlay loop muted disablePictureInPicture>
                <source
                    src="https://auto-master.by/animations/0000-0028.webm"
                    type="video/webm"
                />
              </video>
            </div>
          </div>
          <div className="overlap-21">
            <div className="element-wrapper-7">
              <div className="element-35">
                <video className={"animation"} autoPlay loop muted disablePictureInPicture>
                  <source
                      src="https://auto-master.by/animations/0000-0090.webm"
                      type="video/webm"
                      />
                </video>
              </div>
            </div>
            <div className="div-10">
              <div className="text-wrapper-37">Целостность кузова</div>
              <p className="text-wrapper-32">
                Исследуем авто на предмет попадания в ДТП, замену несущих элементов кузова.
              </p>
            </div>
          </div>
        </div>
        <div className="div-11">
          <div className="features-3">
            <div className="element-36">
              <div className="text-wrapper-38">Постоянная обратная связь</div>
              <p className="with-lots-of-unique-16">
                Получайте отчёты об интересующих&nbsp;&nbsp;автомобилях любым удобным для Вас способом.
              </p>
              <AllIcons12 className="all-icons-4" />
            </div>
            <div className="element-37">
              <div className="manage-customers-3">Экономия денег</div>
              <p className="with-lots-of-unique-17">
                Мы постараемся свести к нулю потраченные на подбор деньги за счёт торга с продавцом.
              </p>
              <AllIcons7 className="all-icons-1" />
            </div>
            <div className="element-38">
              <div className="text-wrapper-38">Экономим Ваше время</div>
              <p className="with-lots-of-unique-18">
                Вам не нужно тратить время за постоянным мониторингом интернет площадок и поездками.
              </p>
              <IconClock className="icons-clock"/>
              <div className="ic-layers-2" />
            </div>
            <div className="element-39">
              <div className="text-wrapper-38">Гибкость</div>
              <p className="with-lots-of-unique-18">
                Учитываем Ваши пожелания даже если они не были оговорены заранее, подстроимся под Ваше расписание.
              </p>
              <AllIcons15 className="all-icons-15" />
            </div>
            <div className="element-40">
              <div className="text-wrapper-38">Уложимся в сроки</div>
              <p className="with-lots-of-unique-18">В 90% случаев подбор авто займёт 1-2&nbsp;&nbsp;недели</p>
              <AllIcons2 className="all-icons-8" />
            </div>
            <div className="element-41">
              <div className="organize-your-campai-5">Юридическая чистота</div>
              <div className="with-lots-of-unique-19">Гарантируем юридическую чистоту сделки.</div>
              <AllIcons9 className="all-icons-17" />
            </div>
          </div>
          <div className="div-wrapper-2">
            <p className="text-wrapper-39">
              Мы знаем, что поиск автомобиля может быть утомительным процессом, но наша миссия — сделать его максимально
            простым и приятным. Наш подход к подбору автомобилей сочетает в себе передовые технологии,
            большой личный опыт в сфере автобизнеса, правильное юридическое оформление. Все это позволит предложить вам автомобили,
            которые идеально соответствуют вашим желаниям и потребностям.
            </p>
          </div>
        </div>
        <div className="title-4">
          <p className="pricing-plans-5">
            <span className="text-wrapper-40">
              Что мы проверяем?
              <br />
            </span>
            <span className="text-wrapper-41">Гарантируем, что все эти пункты будут соблюдены в полной мере</span>
          </p>
        </div>
        <div className="hero-3">
          <div className="overlap-22">
            <div className="BG-5">
              <div className="BG-6" />
            </div>
            <div className="group-3">
              <img
                className="element-gthumb-3"
                alt="Element gthumb"
                src="https://auto-master.by/img/11302-gthumb.png"
              />
              <img
                className="element-peugeot-sw-3"
                alt="Element peugeot sw"
                src="https://auto-master.by/img/15-peugeot-508.png"
              />
              <img
                className="element-42"
                alt="Element"
                src="https://auto-master.by/img/1-02ee71fe.png"
              />
              <div className="rectangle-9" />
              <div className="rectangle-10" />
              <div className="ellipse-3" />
              <img className="images-3" alt="Images" src="https://auto-master.by/img/images-1.png" />
            </div>
            <div className="hjfd-3">
              Поиск идеального
              <br />
              автомобиля начинается здесь
            </div>
            <div className={`header-2 ${headerClassName}`}>
              <div className="shadepro-3">Авто Мастер</div>
              <div className="shadepro-4"><tel>+37533 6574994</tel></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
