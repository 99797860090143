
import React from "react";
import {Box, Button, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField} from "@mui/material";
import "./style.css"
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'







export const CTA = ()=>{
    const [service, setService] = React.useState('');
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [status, setStatus] = React.useState(false);
    const [statusMessage, setStatusMessage] = React.useState('Заявка отправлена');
    const handleChange = (event) => {
        setService(event.target.value);
    };

    const [tel, setTel] = React.useState('')
    const handleTel = (newValue) => {
        setTel(newValue)
        matchIsValidTel(newValue, {
            onlyCountries: ["BY"]
        })
    }
    const handleClose = () => {
        setStatus(false);
    };

    const callRequest = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name:name, tel:tel, email:email, service:service})
        };
        try {
            const response = await fetch(`https://auto-master.by:8443/call-request`, requestOptions);
            const resp = await response.json()
            if (resp.status==="ok") {
                setStatus(true)
            }
            else setStatusMessage('Ошибка, повторите ещё раз')
                setStatus(true);

        } catch (err) {
            console.log(err.message);
        }
    }

    return(
        <div className="CTA">
            <div className="overlap-4">
                <Button onClick={callRequest} className="request-button" variant="contained" color="primary">Заказать звонок</Button>
                <TextField
                    onChange={(v)=>setName(v.target.value)}
                    className="name-field"
                    required
                    id="outlined-required"
                    label="Имя"
                    size="normal"
                />
                {/*<TextField*/}
                {/*    className="tel-field"*/}
                {/*    required*/}
                {/*    id="outlined-required"*/}
                {/*    label="Телефон"*/}
                {/*    size="normal"*/}
                {/*/>*/}
                    <MuiTelInput
                        // disableDropdown
                        defaultCountry="BY"
                        onlyCountries={["BY"]}
                        className="tel-field"
                        value={tel}
                        onChange={handleTel} />



                <TextField
                    className="email-field"
                    id="outlined-required"
                    label="Почта"
                    size="normal"
                    onChange={(v)=>setEmail(v.target.value)}
                />

                <Box className="service-select">
                <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Услуга</InputLabel>
                <Select

                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={service}
                    label="Услуга"
                    onChange={handleChange}
                >
                    <MenuItem value={"Разовый осмотр"}>Разовый осмотр</MenuItem>
                    <MenuItem value={"Эксперт на день"}>Эксперт на день</MenuItem>
                    <MenuItem value={"Подбор автомобиля"}>Подбор автомобиля</MenuItem>
                </Select>
                </FormControl>
                    </Box>

                <Snackbar
                    autoHideDuration={6000}
                    anchorOrigin={{vertical:"bottom", horizontal: "center"}}
                    open={status}
                    onClose={handleClose}
                    message={statusMessage}
                    // key={vertical + horizontal}
                />
            </div>
        </div>
        )

}
