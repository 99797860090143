/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const InputRegular = ({
  className,
  inputClassName,
  inputBoxClassName,
  overlapGroupClassName,
  divClassName,
  text = "Input name",
  divClassNameOverride,
  text1 = "Label",
}) => {
  return (
    <div className={`input-regular ${className}`}>
      <div className={`input ${inputClassName}`}>
        <div className={`input-box ${inputBoxClassName}`}>
          <div className={`input-name-wrapper ${overlapGroupClassName}`}>
            <div className={`input-name ${divClassName}`}>{text}</div>
          </div>
        </div>
        <div className={`label ${divClassNameOverride}`}>{text1}</div>
      </div>
    </div>
  );
};

InputRegular.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
};
