/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const AllIcons9 = ({ className }) => {
  return (
    <svg
      className={`all-icons-9 ${className}`}
      fill="none"
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_45_33091)">
        <path
          className="path-2"
          d="M2.31406 16.8805L11.7008 26.2672C13.4352 28.0016 15.1461 28.0133 16.8687 26.3023L26.3023 16.857C28.0133 15.1461 28.0016 13.4234 26.2672 11.6891L16.8922 2.31406C15.1578 0.579683 13.4469 0.556245 11.7242 2.2789L2.2789 11.7125C0.556245 13.4352 0.579683 15.1461 2.31406 16.8805ZM3.67343 15.5797C2.8414 14.7359 2.81796 13.857 3.68515 12.9898L12.9898 3.68515C13.857 2.81796 14.7359 2.82968 15.5797 3.67343L24.9078 13.0016C25.7398 13.8336 25.7633 14.7242 24.8961 15.5914L15.5914 24.8961C14.7242 25.7633 13.8219 25.7398 13.0016 24.9195L3.67343 15.5797Z"
          fill="black"
          fillOpacity="0.85"
        />
        <path
          className="path-2"
          d="M13.025 19.857C13.4117 19.857 13.7398 19.6695 13.9625 19.3062L19.3297 10.8805C19.4586 10.6461 19.6109 10.3883 19.6109 10.1305C19.6109 9.60315 19.1422 9.26331 18.65 9.26331C18.3453 9.26331 18.0523 9.45081 17.8414 9.79065L12.9781 17.5953L10.6578 14.607C10.3883 14.232 10.1187 14.1383 9.80233 14.1383C9.2867 14.1383 8.89999 14.5484 8.89999 15.0641C8.89999 15.3219 8.99374 15.568 9.16952 15.7906L12.0172 19.3062C12.3219 19.693 12.6266 19.857 13.025 19.857Z"
          fill="black"
          fillOpacity="0.85"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_45_33091">
          <rect className="rect" fill="white" height="26.593" transform="translate(1 1)" width="26.5768" />
        </clipPath>
      </defs>
    </svg>
  );
};
