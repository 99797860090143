/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const AllIcons12 = ({ className }) => {
  return (
    <svg
      className={`all-icons-12 ${className}`}
      fill="none"
      height="28"
      viewBox="0 0 43 28"
      width="43"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_85_264)">
        <path
          className="path-2"
          d="M9.18359 13.6211C11.6445 13.6211 13.6836 11.4297 13.6836 8.74609C13.6836 6.09766 11.668 4 9.18359 4C6.71094 4 4.69531 6.13281 4.69531 8.75781C4.69531 11.4414 6.72266 13.6211 9.18359 13.6211ZM9.18359 11.8516C7.75391 11.8516 6.57031 10.5156 6.57031 8.75781C6.57031 7.05859 7.73047 5.76953 9.18359 5.76953C10.6367 5.76953 11.8086 7.03516 11.8086 8.74609C11.8086 10.4922 10.625 11.8516 9.18359 11.8516ZM3.07812 23.1836H15.2773C16.9766 23.1836 17.8555 22.6328 17.8555 21.4375C17.8555 18.7422 14.7266 14.8281 9.17188 14.8281C3.62891 14.8281 0.5 18.7422 0.5 21.4375C0.5 22.6328 1.36719 23.1836 3.07812 23.1836ZM2.62109 21.4141C2.44531 21.4141 2.375 21.3438 2.375 21.2031C2.375 19.6562 4.58984 16.5977 9.17188 16.5977C13.7539 16.5977 15.9688 19.6562 15.9688 21.2031C15.9688 21.3438 15.8984 21.4141 15.7344 21.4141H2.62109ZM33.4297 13.6797C35.8906 13.6797 37.918 11.4883 37.918 8.80469C37.918 6.15625 35.9141 4.05859 33.4297 4.05859C30.957 4.05859 28.9297 6.19141 28.9297 8.81641C28.9297 11.5 30.957 13.6797 33.4297 13.6797ZM33.4297 11.9102C31.9883 11.9102 30.8047 10.5742 30.8047 8.81641C30.8047 7.11719 31.9766 5.82812 33.4297 5.82812C34.8711 5.82812 36.043 7.09375 36.043 8.80469C36.043 10.5508 34.8711 11.9102 33.4297 11.9102ZM27.3242 23.2422H39.5117C41.2227 23.2422 42.0898 22.6914 42.0898 21.4961C42.0898 18.8008 38.9727 14.8867 33.418 14.8867C27.8633 14.8867 24.7461 18.8008 24.7461 21.4961C24.7461 22.6914 25.6133 23.2422 27.3242 23.2422ZM26.8672 21.4727C26.6914 21.4727 26.6211 21.4023 26.6211 21.2617C26.6211 19.7148 28.8359 16.6562 33.418 16.6562C38 16.6562 40.2148 19.7148 40.2148 21.2617C40.2148 21.4023 40.1445 21.4727 39.9688 21.4727H26.8672Z"
          fill="black"
          fillOpacity="0.85"
        />
        <path
          className="path-2"
          d="M17.4219 13.7266C17.9727 13.7266 18.4297 13.2695 18.4297 12.7187C18.4297 12.168 17.9727 11.7109 17.4219 11.7109C16.8711 11.7109 16.4141 12.168 16.4141 12.7187C16.4141 13.2695 16.8711 13.7266 17.4219 13.7266ZM21.3125 13.7266C21.8633 13.7266 22.3203 13.2695 22.3203 12.7187C22.3203 12.168 21.8633 11.7109 21.3125 11.7109C20.7617 11.7109 20.3047 12.168 20.3047 12.7187C20.3047 13.2695 20.7617 13.7266 21.3125 13.7266ZM25.1797 13.7266C25.7305 13.7266 26.1875 13.2695 26.1875 12.7187C26.1875 12.168 25.7305 11.7109 25.1797 11.7109C24.6289 11.7109 24.1719 12.168 24.1719 12.7187C24.1719 13.2695 24.6289 13.7266 25.1797 13.7266Z"
          fill="black"
          fillOpacity="0.85"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_85_264">
          <rect className="rect" fill="white" height="19.2539" transform="translate(0.5 4)" width="41.5898" />
        </clipPath>
      </defs>
    </svg>
  );
};
