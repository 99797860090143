/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import {Link, animateScroll as scroll } from "react-scroll";

export const ButtonSolidStyleWrapper = ({
  className,
    href,
  overlapGroupClassName,
  rectangleClassName,
  buttonNameClassName,
  divClassName,
  text = "Button name",
  tailRightClassName,
  tailRight = "https://auto-master.by/img/tail-right.png",
}) => {
  return (

    <div className={`button-solid-style-wrapper ${className}`}>
     <Link
     to="cta"
     smooth="true">
       <button className="overlap-group-wrapper">
         <div className={`overlap-group-3 ${overlapGroupClassName}`}>
           <div className={`rectangle ${rectangleClassName}`} />
           <div className={`button-name-wrapper ${buttonNameClassName}`}>
             <div className={`text-wrapper ${divClassName}`}>{text}</div>
           </div>
           <img className={`tail-right ${tailRightClassName}`} alt="Tail right" src={tailRight} />
         </div>
       </button>
     </Link>
      {/*<Button*/}
      {/*    href="#cta"*/}
      {/*    endIcon={<img src={"https://auto-master.by/img/tail-right.png"}/>}*/}
      {/*>Заказать*/}
      {/*</Button>*/}

    </div>
  );
};

ButtonSolidStyleWrapper.propTypes = {
  text: PropTypes.string,
  tailRight: PropTypes.string,
};
