/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const ListStyle = ({
  className,
  divClassName,
  text = "Name your list",
  path = "https://auto-master.by/img/path-66.svg",
}) => {
  return (
    <div className={`list-style ${className}`}>
      <div className="element">
        <div className={`name-your-list ${divClassName}`}>{text}</div>
        <img className="path" alt="Path" src={path} />
      </div>
    </div>
  );
};

ListStyle.propTypes = {
  text: PropTypes.string,
  path: PropTypes.string,
};
