/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { IconsDropdownSolid3 } from "../../icons/IconsDropdownSolid3";
import "./style.css";

export const InputDropdownLabel = ({
  className,
  inputClassName,
  overlapGroupClassName,
  BGSolidClassName,
  BGBorderClassName,
  divClassName,
  text = "Select name",
  iconsDropdownSolid3StyleOverrideClassName,
  divClassNameOverride,
  text1 = "Select label",
}) => {
  return (
    <div className={`input-dropdown-label ${className}`}>
      <div className={`div ${inputClassName}`}>
        <div className={`overlap-group-2 ${overlapGroupClassName}`}>
          <div className={`BG-solid ${BGSolidClassName}`} />
          <div className={`BG-border ${BGBorderClassName}`} />
          <div className={`select-name ${divClassName}`}>{text}</div>
          <IconsDropdownSolid3 className={iconsDropdownSolid3StyleOverrideClassName} />
        </div>
        <div className={`select-label ${divClassNameOverride}`}>{text1}</div>
      </div>
    </div>
  );
};

InputDropdownLabel.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
};
