/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const FooterDarkStyle = ({ className, lineCopy = "https://auto-master.by/img/line-copy.svg" }) => {
  return (
    <div className={`footer-dark-style ${className}`}>
      <div className="sections-footer-dark-3">
        <div className="overlap-group-13">
          <div className="element-27">
            <p className="privacy-policy-terms-3">
              Индивидуальный предприниматель
              <br />
              Артименок Виктор Петрович
              <br />
              Почтовый адрес: Витебская обл.,Глубокский р-н.,г.п. Подсвилье ,пер.1-й Полесский ,д.8
              <br />
              УНП 391538527
              <br />
              BY61AKBB30130000205180000000 Текущий (расчетный)  (BYN)
              <br />в ЦБУ208ОАО « АСБ Беларусбанк» в г. Глубокое
              <br />
              БИК AKBBBY2X
            </p>
          </div>
          <div className="element-28">
            <div className="contact-us-3">Связаться с нами</div>
            <div className="get-a-free-consultan-7">+37533 6574994</div>
            <div className="get-a-free-consultan-8">auto-master.info@yandex.by</div>
          </div>
          <div className="element-29">
            <p className="element-copyright-al-3">© 2024</p>
            <img className="line-copy-3" alt="Line copy" src={lineCopy} />
          </div>
        </div>
      </div>
    </div>
  );
};

FooterDarkStyle.propTypes = {
  lineCopy: PropTypes.string,
};
